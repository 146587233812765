<template>
    <div :class="{'active-menu': activeMenu}">
      <app-menu @changeMenuState="updateMenuState($event)"></app-menu>
      <div class="wrapper">
        <app-header></app-header>
        <!--main-nav-->
        <main>
          <about></about>
          <projects></projects>
          <contacts></contacts>
        </main>
      </div>
  </div>
</template>

<script>
  import AppHeader from '../components/AppHeader'
  import AppMenu from '../components/AppMenu'

  // Views
  import About from '../views/About'
  import Projects from '../views/Projects'
  import Contacts from '../views/Contacts';  
  export default {
  name: 'Home',
  components: {
    AppHeader,
    AppMenu,
    About,
    Projects,
    Contacts
  },
  data () {
    return {
      activeMenu: false,
    }
  },
  methods: {
    updateMenuState: function(state){
      this.activeMenu = state;
    },
  },
  
}
</script>

<style scoped lang="less">
.active-menu {
  z-index: 0;
  background: rgba(0,0,0,0.25);
  .wrapper *{
      -webkit-filter: blur(8px);
      -moz-filter: blur(8px);
      -o-filter: blur(8px);
      -ms-filter: blur(8px);
      filter: blur(8px);
      .transition(0.5s);
  } 
}
.transition(@duration) {
	 	-webkit-transition: all @duration ease-in-out;
	 	-moz-transition: all @duration ease-in-out;
	 	-o-transition: all @duration ease-in-out;
	 	transition: all @duration ease-in-out;
	} 
</style>
