<template>
    <div class="shelf-wrap">
    <div class="shelf">
        <div class="shadow"></div>
        <div class="bookend left"></div>
        <div class="books">
        <div class="book book1">
            <span>Don't Make Me Think</span>
        </div>
        <div class="book book2">
            <span>The Life-Changing Magic of Tidying Up</span>
        </div>
        <div class="book book3">
            <span>
                The Subtle Art of Not Giving a F*ck
            </span>
        </div>
        <div class="book book4">
            <span>Winnie-The-Pooh</span>
        </div>
        <div class="book book5">
            <span>The Ultimate Hitchhiker's <strong>Guide</strong></span>
        </div>
        </div>
        <div class="bookend right"></div>
    <!-- Camera -->
        <div class="camera-wrap">
            <div class="polaroid">
                <div class="button"></div>
                <div class="flash"></div>
                <div class="polaroid-body"></div>
                <div class="blinker"></div>
                <div class="stripes"></div>
                <div class="zoom">
                    <div class="reflections"></div>
                </div>
            </div>
        </div>

    </div>
    </div>
</template>

<style scoped lang="less">
.shelf-wrap {
    padding: 5em 0;
}
    .shelf {
        width: 30em;
        border-bottom: 1em solid #a08673;
        position: relative;
        top: 50%;
        left: 50%;
        margin-top: -5em;
        margin-left: -15em;
        padding: 0 3em;
        display: flex;
        align-items: flex-end;
            &:after, &:before {
                content: '';
                display: block;
                width: 1em;
                height: 2em;
                position: absolute;
                bottom: -3em;
                background: #776659;
            }
        &:before {
            left: 1em;
        }
        &:after {
            right: 1em;
        }
    }

    .shadow {
        position: absolute;
        bottom: -2.7em;
        height: 1.7em;
        left: 1em;
        right: 1em;
        z-index: -1;
        background: linear-gradient(to bottom, #5a2d2b  0%, #fff 100%);
    }

    .bookend {
        width: 3em;
        height: 4em;
        position: relative;
        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            background: #000;
        }
        &:before {
            height: .75em;
            width: 100%;
            bottom: 0;
        }
        &:after {
            height: 100%;
            width: .75em;
        }
    }

    .bookend.left::after{
        right: 0;
    }


    .books {
        display: flex; 
        align-items: flex-end;
    }

    .book {
        overflow: hidden;
        width: 2em;
        height: 9em;
        background: #907d41;
        position: relative;
        &:before {
            height: .5em;
            width: 100%;
            background: rgba(255,255,255,.15);
            content: '';
            display: block;
            position: absolute;
            top: 15%;
        }
        &:after {
            height: .5em;
            width: 100%;
            background: rgba(255,255,255,.15);
            content: '';
            display: block;
            position: absolute;
            bottom: 5%;
        }
        span {
            -webkit-transform: rotate(90deg);
            -moz-transform: rotate(90deg);
            -o-transform: rotate(90deg);
            -ms-transform: rotate(90deg);
            transform: rotate(90deg);
            display: block;
            position: relative;
        }
        

        // Book1
        &.book1{
            height: 10em;
            width: 1.7em;
            background: #e8cd38;
            span {
                width: 160px;
                top: 50%;
                left: -66px;
                font-size: 0.9em;
            }
        }
        // Book2
        &.book2 {
            height: 15em;
            width: 3em;
            background: #bbafb3;
            span {
                width: 161px;
                top: 114px;
                left: -57px;
                color: rgb(18, 45, 119);
            }
        }
        &.book3{
            height: 13em;
            width: 4em;
            background: #fc4f05;
            span {
                width: 160px;
                top: 98px;
                left: -50px;
                font-size: 0.9em;
                font-weight: bold;
            }
        }
        &.book4{
            height: 10em;
            width: 1.7em;
            background: #fffbce;
            border-top: 1px solid #999;
            border-left: 1px solid #999;
            border-right: 1px solid #999;
            span {
                width: 160px;
                top: 50%;
                left: -66px;
                font-size: 0.9em;
            }
        }
        &.book5{
            height: 12em;
            width: 5em;
            background: #4caf50;
            span {
                width: 173px;
                top: 70px;
                left: -51px;
                font-size: 1em;
                color: #fff;
                strong {
                    font-size: 1.8em;
                    display: block;
                    text-transform: uppercase;
                }
            }
        }
    }

// Camera
@grey: #34495e;
@dark-grey: #212f3d;
@red: #cb214a;
@duration: 5s;

.camera-wrap * {
	position: absolute;
    &:before, &:after {
        content: "";
        position: absolute;
    }
}
.polaroid {
    bottom: 0px;
    right: 30px;
	width: 120px;
	height: 80px;
	background-color: #fff;
	border-radius: 10px;
    border-top: 2px solid #ccc;
    box-shadow: 2.5px 8px 8px rgb(124, 120, 120);
    .polaroid-body {
        background-color: #fff;
        border-radius: 20px;
        width: 100%;
        height: 100%;
    }
}



.flash {
	width: 24%;
	height: 15%;
	background-color: @grey;
	border-radius: 8px;
	top: -12%;
	left: 38%;
	&:after {
		width: 36%;
		height: 60%;
		background-color: lighten(@grey, 30%);
		border-radius: 5px;
		top: 10%;
		left: 32%;
	}

}
.button {
	background-color: @red;
	border-radius: 10px;
	width: 10%;
	height: 6px;
	top: -6px;
	left: 14%;
	animation: button @duration linear infinite;
}
.blinker {
	width: 15px;
	height: 15px;
	top: 15%;
	left:15%;
	border-radius: 15px;
	background-color: @dark-grey;
	animation: blinker @duration linear infinite;
}
.zoom {
	border-radius: 50%;
	width: 50px;
	height: 50px;
	background: @dark-grey;
	left: 25%;
	top: 15%;
	box-shadow: 5px 5px rgba(0, 0, 0, 0.1);
	overflow: hidden;
	border: 5px solid @grey;
	animation: zoom @duration linear infinite;
	&:before {
		width: 80%;
		height: 80%;
		top: 10%;
		left: 10%;
		border-radius: 50%;
		background: #2c3e50;
		border: 10px solid @grey;
	}
	&:after {
		width: 40%;
		height: 40%;
		top: 30%;
		left: 30%;
		border-radius: 50%;
		background: @dark-grey;
	}
}
.reflections {
	background-color: #fff;
	opacity: 0.3;
	width: 100%;
	height: 100%;
	left: -35%;
	top: -35%;
	z-index: 99;
	transform: rotate(-45deg);
	&:after {
		background-color: #fff;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		left: 50%;
		top: 105%;
		opacity: 0.5;
	}
}

.stripes {
    right: 0;
    top: 35%;
    height: 2px;
    width: 50%;
    box-shadow: 0px 2px #d60079, 0px 4px #e68d15, 0px 6px #fec803, 0px 8px #dde56b, 0px 10px #3ea230, 0px 12px #0188c2;
}

@keyframes button {
	5%, 15% {
		transform:translateY(0);
	}
	10% {
		transform:translateY(2px);
	}
}

@keyframes zoom {
	20%, 32%  {
		transform:rotate(0deg);
	}
	26% {
		transform:rotate(20deg);
	}
}

@keyframes blinker {
	32%, 37%, 39%, 43%, 45%, 50% {
		background-color: @dark-grey;
	}
	34%, 36%, 40%, 42%, 46%, 48% {
		background-color: @red
	}
}

@media only screen and (max-width: 486px){
    .shelf {
        width: 192px;
        margin-left: -142px;
    }
    .book2, .book4 {
        display: none;
    }
    .polaroid {
        right: 3px;
    }
}

</style>

<script>
export default {
  name: 'Books',
}
</script>

