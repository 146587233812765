<template>
    <div id="contacts">
      <div class="row">
          <h3>Do you believe in good KARMA? <em>.. I do.</em></h3>
          <books></books>

          <p>In order to receive some good KARMA, you could send me some positive thoughts or use more typical approaches</p>
          <ul>
              <li class="gmail"><a href="mailto:militsa.mokanova@gmail.com">mail</a></li>
              <li class="insta"><a href="https://www.instagram.com/militsamokanova/" target="_blank">insta</a></li>
              <li class="linkedin"><a href="https://www.linkedin.com/in/militsa-mokanova-8417438a/" target="_blank">linkedIn</a></li>
              <li class="cv"><a href="https://drive.google.com/file/d/1q2RUlY0eMIJV7AtW-Ww-n0NYN3b4xJRt/view?usp=sharing" target="_blank">cv</a></li>
          </ul>
          <div class="copyright">Made with ♥ Mivamo &copy; 2014-2021</div>
      </div>
    </div>
</template>

<script>
    import Books from '../components/Books'
    export default {
        name: 'Contacts',
        components: {
            Books,
        },  
    }
</script>

<style scoped lang="less">
.transition(@duration) {
	 	-webkit-transition: all @duration ease-in-out;
	 	-moz-transition: all @duration ease-in-out;
	 	-o-transition: all @duration ease-in-out;
	 	transition: all @duration ease-in-out;
	}
    #contacts {
    padding: 2em 1em 10px;
         h2 {
            font-size: 3em;
            color: #fff;;
            display: block;
            text-shadow: 0px 0px 3px black; 
            text-transform: uppercase;
        }
        h3 {
            em {
                color:orange;
                display: block;
            }
        }
        p {
            font-size: 1em;
            padding-bottom: 5px;
            border-bottom: 1px solid orange;
            display: inline-block;
        }
        ul {
            list-style: none;
            padding: 0;
            li {
                display: inline-block;
                background: #c7c7c7; 
                border: 7px solid #ffffff;
                box-shadow: 1px 1px 3px #000;

                border-radius: 100%;
                color: #fff;
                margin-right: 1em;
                margin-bottom: 1em;
                .transition(0.3s);
                &:hover {
                    box-shadow: 0px -2px 4px #000;
                }
                a {
                    display: block;
                    border-radius: 100%;
                    padding: 1em;
                    height: 1em;
                    width: 1em;
                    line-height: 1em;
                    background-image: url('../assets/img/social-sprite.png');
                    background-repeat: no-repeat;
                    background-size: 150px;
                    text-indent: -9999px;
                }
                &.gmail {
                    a {
                        background-position: 9px 9px;
                    }
                    &:hover {
                        background: #e11e26;
                    }
                }
                &.insta {
                    a {
                        background-position: -33px 9px;
                    }
                    &:hover {
                        background: #ffcf68; /* Old browsers */
                        background: -moz-linear-gradient(45deg,  #ffcf68 14%, #ee4588 56%, #974d9e 100%); 
                        background: -webkit-linear-gradient(45deg,  #ffcf68 14%,#ee4588 56%,#974d9e 100%); 
                        background: linear-gradient(45deg,  #ffcf68 14%,#ee4588 56%,#974d9e 100%); 
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffcf68', endColorstr='#974d9e',GradientType=1 ); 
                    }
                }
                &.linkedin {
                    a {
                        background-position: -74px 6px;
                    }
                    &:hover {
                        background: #0066b2;
                    }
                }
                &.cv {
                    a {
                        background-position: -115px 7px;
                    }
                    &:hover {
                        background: #2cca7f;
                    }

                }
            }
        }
    }

    .copyright {
        margin-top: 5em;
        font-size: 0.85em;
        color: #c7c7c7;
    }
    @media only screen and (min-width: 768px) {
        #contacts {
            padding: 7em 1em 10px;
        }
    }

    @media only screen and (min-width: 1024px) {
        #contacts {
            padding: 12em 1em 10px;
            h2 {
                font-size: 4em;
            }
            h3 {
                font-size: 3em;
                em {
                    font-size: 1.2em;
                    display: inline-block;
                }
            }
            p {
                font-size: 1em;
            }
            ul li{
                margin-right: 2em;
                 a {
                    padding: 1em;
                    height: 2em;
                    width: 2em;
                    line-height: 2em;
                    background-size: 200px;
                }
                &.gmail {
                    a {
                        background-position: 12px 10px;
                    }
                }
                &.insta {
                    a {
                        background-position: -44px 10px;
                    }
                }
                &.linkedin {
                    a {
                        background-position: -100px 8px;
                    }
                }
                &.cv {
                    a {
                        background-position: -154px 8px;
                    }
                }
            }
        }
    }
</style>
