<template>
    <div class="viewport-header">
        <h1><span>{{ msg }}</span> <span class="random-word">{{randomWord}}</span></h1>
    </div>
</template>
<script>

const wordsArr = ['sea', 'sky', 'art', 'music', 'waves', 'birds', 'nature', 'photos', 'code', 'games', 'cats', 'dogs', 'colors', 'cake', 'travel', 'cinema', 'sunset', 'sunrise', 'Varna', 'Sofia', 'Burgas', 'Plovdiv', 'Paris'];
export default {
  name: 'AppHeaderTitle',
    data () {
    return {
        msg: 'I LOVE',
        randomWord: "THIS",
    }
  },
  prpos: {
    menuState:Function,
  },
   methods: {
    getRandomWord: function(){
      this.randomWord = wordsArr[Math.floor(Math.random() * wordsArr.length)];
    },
  },
  mounted: function(){
    this.getRandomWord();
    setInterval(this.getRandomWord, 1000);
  },
}
</script>
<style scoped lang="less">
    .viewport-header {
        position: absolute;
        top: 25%;
        width: 100%;
        // margin-left: -10vw;
        text-align: center;
        
        h1 {
            font-size: 3em;
            span {  
                text-shadow: 2px 2px 10px #000;
                color: #fff;
                &.random-word {
                    display: block;
                    text-transform: uppercase;
                    // position: absolute;
                    // padding-left: 10px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                }
                &:not(.random-word) {
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                }
            }
        }
    }

    // Reveal animation
    h1,h1:after {
        animation-delay: var(--animation-delay, 2s);
        animation-iteration-count: var(--iterations, 1);
        animation-duration: var(--duration, 800ms);
        animation-fill-mode: both;
        animation-timing-function: cubic-bezier(0.0, 0.0, 0.2, 1);
    }

    h1 {
        --animation-delay: var(--delay, 0);
        --animation-duration: var(--duration, 800ms);
        --animation-iterations: var(--iterations, 1);
        position: relative;
        font-size: 10vw;
        animation-name: clip-text;
        color: #FFF;
        white-space: nowrap;
        cursor: default;
        
        &::after {
            content: "";
            position: absolute;
            z-index: 999;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #fff;
            transform: scaleX(0);
            transform-origin: 0 50%;
            pointer-events: none;
            animation-name: text-revealer;
        }
        
    }

    @keyframes clip-text {
        from {
            clip-path: inset(0 100% 0 0);
        }
        to {
            clip-path: inset(0 0 0 0);
        }
    }


    @keyframes text-revealer {
        
        0%, 50% {
            transform-origin: 0 50%;
        }
        
        60%, 100% {
            transform-origin: 100% 50%;		
        }

        
        60% {
            transform: scaleX(1);
        }
        
        100% {
            transform: scaleX(0);
        }
    }
@media only screen and (min-width: 712px) {
    .viewport-header {
        h1 {
           span {
                margin-left: -20vw;
                &.random-word {
                    display: inline-block;
                    position: absolute;
                    padding-left: 10px;
                    margin-left: 0;
                }
           }
        }
    }
}
@media only screen and (min-width: 1024px) {
    .viewport-header {
        top: 30%;

        h1{
            font-size: 6em;
            span {
                margin-left: -30vw;
                &.random-word {
                    padding-left: 30px;
                }
            }
        }
    }
}

@media only screen and (min-width: 1260px) {
    .viewport-header {
        h1{
            font-size: 7em;
            span {
                margin-left: -20vw;
            }
        }
    }
}

</style>
