<template>
  <div :class="{scrolled: scrollPosition > 100}">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>
<script>
  export default {
    data () {
      return {
          scrollPosition: null
      }
    },

    methods: {
      updateScroll() {
        this.scrollPosition = window.scrollY
      }
    },

    mounted() {
      window.addEventListener('scroll', this.updateScroll);
    },
     watch: {
      '$route' (to, from) {
        document.title = to.meta.title || 'MIVAMO'
      }
    },
  
  }
  
</script>
<style lang="less">
html, body {
  padding: 0;
  margin: 0;
  height: 100%;
  scroll-behavior: smooth;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
