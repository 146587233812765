<template>
    <div id="menu">
        <a class="target-burger" @click="toggleMenu" :class="{toggled: showMenu}">
        <ul class="buns">
          <li class="bun"></li>
          <li class="bun"></li>
        </ul>
        <!--buns-->
        </a>
      <!--target-burger-->
      <nav class="main-nav" :class="{toggled: showMenu}" role="navigation">
        <ul>
          <li><a href="/" @click="toggleMenu"><span>Home</span></a></li>
          <li><a href="#about" @click="toggleMenu"><span>About</span></a></li>
          <li><a href="#projects" @click="toggleMenu"><span>Projects</span></a></li>
          <li><a href="#contacts" @click="toggleMenu"><span>Contacts</span></a></li>
        </ul>
      </nav>
    </div>
</template>

<script>
export default {
  props: ['showMenu'],
  name: 'AppHeader',
    data () {
    return {
         showMenu: false,
    }
  },
  prpos: {
    menuState:Function,
  },
   methods: {
    toggleMenu(){
      this.showMenu = !this.showMenu;
      this.$emit('changeMenuState', this.showMenu);

    },
  },
}
</script>
<style scoped lang="less">
.scrolled {
    #menu {
        a.target-burger {
            background: rgba(0,0,0,0.5);
            margin-left: 0;
            &.toggled {
                background: none;
            }
        }
    }
}
  #menu {
    display: inline-block;
    position: fixed;
    left: 0;
    top:5%;
    color: #fff;
    width: 100%;
    z-index: 9999;
    a.target-burger{
          width: 2.250em;
          height: 2.250em;
          position: absolute;
          display: block;
          z-index: 1002;
          margin-left: 5%;
          .transition(0.5s);
          &:hover{
              cursor: pointer;
              .opacity(0.45);
          }
          &.toggled{
              ul.buns{
                  li.bun{
                      -webkit-transform: rotate(45deg) translateZ(0);
                      transform: rotate(45deg) translateZ(0);
                      &:last-child{
                          -webkit-transform: rotate(-45deg) translateZ(0);
                          transform: rotate(-45deg) translateZ(0);
                      }
                  }
              }
          }
          ul.buns{
              width: 1.625em;
              height: 1.625em;
              list-style: none;
              margin: -1.625em/2 0 0 -1.625em/2;
              padding: 0;
              position: absolute;
              top: 50%;
              left: 50%;
              -webkit-transition: -webkit-transform 1s cubic-bezier(.23,1,.32,1),color 1s cubic-bezier(.23,1,.32,1);
              transition: transform 1s cubic-bezier(.23,1,.32,1),color 1s cubic-bezier(.23,1,.32,1);
              -webkit-transform: translateZ(0);
              transform: translateZ(0);
              color: #fff;
              li.bun{
                  width: 100%;
                  height: 2px;
                  background-color: #fff;
                  position: absolute;
                  top: 50%;
                  margin-top: -.75px;
                  -webkit-transform: translateY(-3.75px) translateZ(0);
                  transform: translateY(-3.75px) translateZ(0);
                  -webkit-transition: -webkit-transform 1s cubic-bezier(.23,1,.32,1),background-color 1s cubic-bezier(.23,1,.32,1);
                  transition: transform 1s cubic-bezier(.23,1,.32,1),background-color 1s cubic-bezier(.23,1,.32,1);
                  box-shadow: 0px 0px 2px black;

                  &:last-child{
                      -webkit-transform: translateY(3.75px) translateZ(0);
                      transform: translateY(3.75px) translateZ(0);
                  }
              }
          }
    }

    nav.main-nav{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        .opacity(0);
        -webkit-transform: scale(0.85);
        -ms-transform: scale(0.85);
        transform: scale(0.85);
        z-index: 0;
        display: none;
        &.toggled{
            display: inline-block;
            z-index: 1001;
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
            .opacity(1);
            text-align: center;

        }
        ul{
            padding: 7em 0 0 0;
            list-style: none;
            li{
                a{
                    font-size: 2em;
                    color: #fff;
                    padding: 0.5em 0;
                    font-weight: 300;
                    display: inline-block;
                    text-decoration: none;
                    text-shadow: 0px 0px 7px #000;
                    text-transform: uppercase;
                    .transition(0.5s);
                    &:last-child{
                        border: none;
                    }
                    &:hover{
                        .transition(0.5s);
                        padding: 1em;
                        background: hsla(0, 0%, 100%, 0.05);
                        border-radius: 5px;
                        -webkit-backdrop-filter: blur(10px);
                        backdrop-filter: blur(10px);
                    }
                }
            }
        }
}

  }
	.opacity (@opacity) {
		-webkit-opacity: 	@opacity;
		-moz-opacity: 		@opacity;
		opacity: 		@opacity;
	}
	.border-radius (@radius) {
		-webkit-border-radius: @radius;
		-moz-border-radius:    @radius;
		border-radius:         @radius;
		-moz-background-clip:    padding;
		-webkit-background-clip: padding-box;
		background-clip:         padding-box;
	}
	.box-shadow (@string) {
		-webkit-box-shadow: @string;
		-moz-box-shadow:    @string;
		box-shadow:         @string;
	}
	.text-shadow (@string) {
		-webkit-text-shadow: @string;
		-moz-text-shadow:    @string;
		text-shadow:         @string;
	}
	.transition(@duration) {
	 	-webkit-transition: all @duration ease-in-out;
	 	-moz-transition: all @duration ease-in-out;
	 	-o-transition: all @duration ease-in-out;
	 	transition: all @duration ease-in-out;
	}
	.animated(@duration) {
	 	-webkit-animation-duration: @duration;
	 	-moz-animation-duration: @duration;
	 	animation-duration: @duration;
	 	-webkit-animation-fill-mode: both;
	 	-moz-animation-fill-mode: both;
	 	animation-fill-mode: both;
	}
 @media only screen and (min-width: 375px) {
     #menu nav.main-nav {
            ul {
                padding: 4em 0 0 0;

                li {
                    a {
                        font-size: 2.5em;
                        padding: 1em 0;
                    }
                }
            }
        }
 }
    @media only screen and (min-width: 1024px) {
        #menu nav.main-nav {
            ul {
                    padding: 3em 0 0 0;

                li {
                    a {
                        font-size: 3em;
                        padding: 1em 0;
                    }
                }
            }
        }
        .scrolled {
            #menu {
                a.target-burger {
                    width: 3.250em;
                    height: 3.250em;
                }
            }
        }
    }
  </style>