<template>
    <div id="projects">
        <section>
            <h2>Projects</h2>
        </section>
        <content>
            <div class="container">
                <div id="firstCol" class="col">
                    <div id="frame1" @click="toggleInfo">
                        <img src="../assets/img/projects/cc_com.jpg" alt="Panel 3">
                        <div class="info" :class='{visible:showProjectInfo}'>
                            <div class="wrap">
                                <h3>CasinoCom</h3>
                                <p>From 2016-2021 working in the Casino industry projects. Involved in rebranding and extend functionalities.</p>
                            </div>
                        </div>
                    </div>
                    <div id="frame2">
                        <img src="../assets/img/projects/sportal.jpg" alt="Panel 2">
                        <div class="info">
                            <div class="wrap">
                                <h3>Sportal</h3>
                                <p>The company, where my Web Development journey started. Worked on: slicing designs, promotional banner wrappers, pure HTML games etc.</p>
                            </div>
                        </div>
                    </div>
                    <div id="frame3">
                        <img src="../assets/img/projects/cat.jpg" alt="Panel 1">
                        <div class="info">
                            <div class="wrap">
                                <h3>GeekyCat</h3>
                                <p>Created a serries of cats for christmas cards. A personal project to practice using my Wacom device.</p>
                                <router-link to="/my-cat">🔗</router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="secondCol" class="col">
                    <div id="frame4">
                        <img src="../assets/img/projects/kab-bolig.jpg" alt="Panel 1">
                        <div class="info">
                            <div class="wrap">
                                <h3>KAB-Bolig</h3>
                                <p>Learned how to create, use and maintain custom Content Management System.</p>
                            </div>
                        </div>
                    </div>
                    <div id="frame5">
                        <img src="../assets/img/projects/jan-14-after-holidays-cal-1280x720.jpg" alt="Panel 1">
                        <div class="info">
                            <div class="wrap">
                                <h3>SmashingMagazine</h3>
                                <p>Participating with a set of free wallpapers created by chance, cause of the glass.</p>
                                <a href="https://www.smashingmagazine.com/2013/12/desktop-wallpaper-calendars-january-2014/" target="_blank">🔗</a>
                            </div>
                        </div>
                    </div>
                    <div id="frame6">
                        <img src="../assets/img/projects/mfg.jpg" alt="Panel 1">
                        <div class="info">
                            <div class="wrap">
                                <h3>MFG Group</h3>
                                <p>During the period I was working for EasyCredit company. My main duties were to create responsive websites, 
                                    but I was also involved in SEO researches and implementations.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </content>
    </div>

</template>
<style lang="less" scoped>
	.transition(@duration) {
	 	-webkit-transition: all @duration ease-in-out;
	 	-moz-transition: all @duration ease-in-out;
	 	-o-transition: all @duration ease-in-out;
	 	transition: all @duration ease-in-out;
	}
#projects {
    padding: 0 1.5em;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.2fr 1.5fr;
    grid-template-areas: "section section" "content content";
    max-width: 100%;
    section {
        grid-area: section;

         h2 {
            font-size: 3em;
            color: #fff;;
            display: block;
            text-shadow: 0px 0px 3px black; 
            text-transform: uppercase;
        }
    }
    content {
        grid-area: content;
        font-size: 25px;

        .container {
            display: block;

            #firstCol, #secondCol {
                grid-gap: 1em;
                img {
                    max-height: 100%;
                    max-width: 100%;
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                }
                > div {
                    position: relative;
                    background-color: #fff;
                    box-shadow: inset 0 0 2px hsla(0,0%,0%,.2),
                                0 3px 1px hsla(0,0%,100%,.75),
                                0 -1px 1px 2px hsla(0,0%,0%,.1);
                    
                    padding: .5em;
                    margin: 0 3em 6em;
                    &:hover, .info.visible {
                        img{
                            -moz-filter: blur(8px);
                            -o-filter: blur(8px);
                            -ms-filter: blur(8px);
                            filter: blur(8px);
                        }
                        .info {
                            opacity: 1;
                        }
                    }
                    .info {
                        background: rgba(0,0,0,0.7);
                        height: 100%;
                        width: 100%;
                        color: #fff;
                        position: absolute;
                        top: 0;
                        left: 0;
                        opacity: 0;
                        .transition(1s);
                        .wrap {
                            position: absolute;
                            top: 50%;
                            transform: translateY(-50%);
                            text-align: center;
                            p {
                                max-width: 60%;
                                margin: 0 auto 10px;
                            }
                            a {
                                color: #fff;
                                text-decoration: none;
                                font-size: 18px;
                            }
                        }
                    }
         /* Frame */
                &:before {
                    background-color: #a08673;
                    bottom: -2.5em;
                    box-shadow: inset 0 1px 1px 1px hsla(0,0%,100%,.2),
                                inset 0 -2px 1px hsla(0,0%,0%,.4),
                                0 5px 50px hsla(0,0%,0%,.25),
                                0 20px 20px -15px hsla(0,0%,0%,.2),
                                0 30px 20px -15px hsla(0,0%,0%,.15),
                                0 40px 20px -15px hsla(0,0%,0%,.1);
                    content: '';
                    left: -2.5em;
                    position: absolute;
                    right: -2.5em;
                    top: -2.5em;
                    z-index: -1;
                }

        /* Mat */
        &:after {
            background-color: #fff5e5;
            bottom: -1.5em;
            box-shadow: 0 2px 1px hsla(0,0%,100%,.2),
                        0 -1px 1px 1px hsla(0,0%,0%,.4),
                        inset 0 2px 3px 1px hsla(0,0%,0%,.2),
                        inset 0 4px 3px 1px hsla(0,0%,0%,.2),
                        inset 0 6px 3px 1px hsla(0,0%,0%,.1);
            content: '';
            left: -1.5em;
            position: absolute;
            right: -1.5em;
            top: -1.5em;
            z-index: -1;
        }
    
                }
            }
            
        }

    }
    

}
@media only screen and (max-width: 467px){

    #projects content .container {
         #firstCol, #secondCol {
             > div .info .wrap {
                 font-size: 17px;
                 width: 100%;
                 h3 {
                     margin: 5px 0;
                 }
                 p {
                    display: none;
                 }
             }
             #frame5 {
                 h3 {
                     text-overflow: ellipsis;
                     overflow: hidden;
                     white-space: nowrap;
                 }
             }
         }
     }
}
@media only screen and (min-width: 468px) and (max-width: 1024px){
     #projects content .container {
         #firstCol, #secondCol {
             > div .info .wrap {
                 font-size: 14px;
                 p {
                     max-width: 95%;
                 }
             }
         }
     }
}
@media only screen and (max-width: 1023px){
     #projects content{
         max-width: 600px;
         margin: 0 auto;
     }
}


@media only screen and (min-width: 1024px) {
    #projects {
        grid-template-columns: auto;
        section h2 {
            font-size: 4em;
            margin-bottom: 2.5em;
        }
        content {
            font-size: 9px;
            .container {
                display: flex;
                #firstCol, #secondCol {
                    > div {
                        margin: 0 3em 6em;
                    }
                }
            }
            #firstCol {
                padding-right: 1em;
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 22em 20em;
                grid-template-areas: 
                    "frame1 frame1" 
                    "frame2 frame3";
                #frame1 {
                    grid-area: frame1;
                    margin-left: 25%;
                    max-width: 75%;

                }
                #frame2 {
                    grid-area: frame2;
                    max-height: 60%;
                    width: 60%;
                    margin-left: 26%;
                    align-self: end;
                    .info .wrap p{
                        max-width: 95%;
                    }
                }
                #frame3 {
                    grid-area: frame3;
                }
            }
            #secondCol {
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 20em 22em;
                grid-template-areas: 
                    "frame4 frame5" 
                    "frame6 frame6";
                
                justify-content: normal;
                place-content: normal;

                #frame4 {
                    grid-area: frame4;
                    align-self: end;
                    height: 90%;
                }
                #frame5 {
                    grid-area: frame5;
                    height: 50%;
                    width: 50%;
                    align-self: end;
                }
                #frame6 {
                    grid-area: frame6;
                    width: 60%;
                    margin-bottom: 4em;
                }
            }
        }
    }
}

@media only screen and (min-width: 1300px) {
    #projects {
        content {
            font-size: 15px;
        }
    }
}

//   /**********/
//  /* Slider */
// /**********/


</style>

<script>
export default {
  name: 'Projects',
    data () {
    return {
         showProjectInfo: false,
    }
  },
   methods: {
    toggleInfo(){
      this.showProjectInfo = !this.showProjectInfo;

    },
  },
}

  </script>