<template>
    <header class="video-header">
        <video width="320" height="240" autoplay loop playsinline muted class="waves-video">
          <source src="../assets/video/sea.mp4" type="video/mp4">
          <!-- <source src="movie.ogg" type="video/ogg"> -->
        </video>  
        <app-header-title></app-header-title>
        <mouse-scroll-down></mouse-scroll-down>
        
    </header>
</template>

<script>
  import AppHeaderTitle from '../components/AppHeaderTitle.vue';
  import MouseScrollDown from '../components/MouseScrollDown.vue';

export default {
  name: 'AppHeader',
  components: {
    AppHeaderTitle,
    MouseScrollDown,
  },
    data () {
    return {
        //  showMenu: false,
    }
  },
  //  methods: {
  //   updateMenuState: function(state){
  //     this.activeMenu = state;
  //   },
  // },
}
</script>
<style scoped lang="less">
header {
  text-align: center;

}

.waves-video {
  background: rgb(165, 199, 230);
  object-fit: cover;
}
.coverer() {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.video-header {
  position: relative;
  text-align: center;
  width: 100vw;
  height: 100vh;
  
  &, video {
    max-width: 100%;
    .coverer();
  }
  video {
    background: #415e54;
    object-fit: cover;
  }
  
}
  </style>