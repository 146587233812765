<template>
  <div id="about">
             <div class="row"> <div><h2>Stop by to say <span>"Hello"</span></h2></div></div>

            <div class="row">
                <div class="hero-img">
                  <Bubbles></Bubbles>
                  <img src="../assets/img/cocktail.png" alt="" height="500">
                </div>
            
            <div class="content">
              <div class="info">
              <p>I'm <strong>Mokanova</strong>, Militsa Mokanova. A Full Stack Developer from Bulgaria. With more than 9 years of professional experience.</p>
              <p>I am obsessed with design and innovative technologies. It's what gets me out of bed in the morning (besides <a href="/my-cat">my cat</a>).</p>
            </div>
            </div>
            
            </div>
          </div>
</template>


<script>
  import Bubbles from '../components/Bubbles.vue'
  
  export default {
  name: 'Home',
  components: {
    Bubbles,
  },
  }
  </script>

  <style scoped lang="less">
  @blueColor: #0158dc; 
@color: #33e3ff;
@waveColor: #f3ffff;
#about {
  padding: 100px 0;
  min-height: calc(100vh-200px);
  h2 {
      font-size: 0.85em;
      text-transform: uppercase;
      span {
        font-size: 4em;
        color: #fff;
        font-style: italic;
        display: block;
        text-shadow: 0px 0px 3px black;
      }
    }
  .row {
    .content {
      font-size: 0.85em;
    }
    .info {
        width: 100%;
        margin-top: 20%;
         p {
          font-size: 2.3vh;
          text-align: left;
          padding: 0.5em 1em;
          span {
            color: black;
          }
          b {
            text-transform: uppercase;
            color: @waveColor;
            text-shadow: 0px 0px 2px black;
            span {
              text-shadow: none;
            }
          }
          a {
            color: #000;
          }
        }
      }
     
  }
}
main {
  .hero-img {
      max-width: 100%;
      text-align: center;
      position: relative;
      img {
        height: 200px;
      }
    }
  
}
  
@media only screen and (min-width: 1024px) {
  #about {
    padding: 200px 0;
    .row {
      display: flex;
      > div {
        justify-content: center;
      }

        .info {
           p {
            font-size: 1em;
            text-align: justify;
            padding: 0 1em;
           }
        }
        .hero-img {
          order: 2;
          margin-top: -200px;
            img{
              height: 500px;
              margin-left: 10em;
          }
        }
        .content {
            padding: 0;
            width: 38%;
            padding-left: 12rem;
            font-size: 1em;
            order: 1;
           
        }
    }
     h2 {
        font-size: 2.5em;
        margin-bottom: 2.5em;
        text-align: left;
        padding-left: 5em;
    }
  }
}
  </style>